/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@package/config');
import eeacmsvoltoObjectWidget from '@eeacms/volto-object-widget';
import voltoSlate from 'volto-slate';
import eeacmsvoltoResizeHelper from '@eeacms/volto-resize-helper';
import eeacmsvoltoBlockStyle from '@eeacms/volto-block-style';
import eeacmsvoltoBlockImageCards from '@eeacms/volto-block-image-cards';
import eeacmsvoltoColumnsBlock from '@eeacms/volto-columns-block';
import kitconceptvoltoBlocksGrid from '@kitconcept/volto-blocks-grid';
import voltoSubblocks from 'volto-subblocks';
import voltoFormBlock from 'volto-form-block';
import voltoCookieBanner from 'volto-cookie-banner';

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [eeacmsvoltoObjectWidget, voltoSlate, eeacmsvoltoResizeHelper, eeacmsvoltoBlockStyle, eeacmsvoltoBlockImageCards, eeacmsvoltoColumnsBlock, kitconceptvoltoBlocksGrid, voltoSubblocks, voltoFormBlock, voltoCookieBanner];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
